/* ==========================================================================
*
* Custom CSS Stylesheet. This is where you should add your own styles!
*
** ========================================================================== */

/* Simple style overrides or custom adds */

.some-css-selector {
    font-size: 13px;
}

.webp .maps {
    background-image: url(../images/chile.png);
}
.no-webp .maps {
    background-image: url(../images/chile.png);
}
/* ====================== 
* Index INICIO
========================*/

.no-webp .index .bg-slider-1 {
    background-image: url(../images/sliders/pexels-christina-morillo-1181467.jpg);
}
.webp .index .bg-slider-1 {
    background-image: url(../images/sliders/pexels-christina-morillo-1181467.webp);
}
.no-webp .index .bg-slider-2 {
    background-image: url(../images/sliders/other-pagebg.jpg);
}
.webp .index .bg-slider-2 {
    background-image: url(../images/sliders/other-pagebg.webp);
}
.no-webp .index .bg-slider-3 {
    background-image: url(../images/servicios/team1.jpg);
}
.webp .index .bg-slider-3 {
    background-image: url(../images/servicios/team1.webp);
}
.no-webp .index .kl-bg-source__bgimage {
    background-image: url(../images/bg/video-bg.jpg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}
.webp .index .kl-bg-source__bgimage {
    background-image: url(../images/bg/video-bg.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}

/* ====================== 
* Index FIN
========================*/

/* ====================== 
* Servicios INICIO
========================*/
.no-webp .servicios .kl-bg-source__bgimage {
    background-image: url(../images/bg/pexels-thisisengineering-3861972.jpg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}
.webp .servicios .kl-bg-source__bgimage {
    background-image: url(../images/bg/pexels-thisisengineering-3861972.webp);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
}

.servicios .img-box {
    position: relative;
    width: 90%;
    height: min-content;
}
.servicios .imagen1 {
    width: 100%;
    height: 100%;
}

.servicios .imagen2 {
    position: absolute;
    right: -10px;
    bottom: 10px;
    width: 50%;
    border: 10px solid white;
}

.servicios .imagen3 {
    position: absolute;
    right: -10px;
    top: 10px;
    width: 50%;
    border: 10px solid rgb(29, 160, 180);
}

.servicios .imagen4 {
    position: absolute;
    left: -10px;
    bottom: 10px;
    width: 50%;
    border: 10px solid rgb(29, 160, 180);
}

.servicios .text1 {
    font-weight: bold;
    color: #106ee9;
}
.servicios .text2 {
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.servicios .box_contenedor {
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
}

.servicios .single_box {
    display: block;
    transition: 0.3s;
    z-index: 1;
    position: relative;
    box-shadow: 0px 8px 16px 0px rgba(0, 109, 232, 0.04);
    color: #ffffff;
    font-size: 23px;
    font-weight: 700;
    line-height: 41px;
    background-color: #ff5f6e;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 17px 0px;
}

.servicios .single_box::after {
    display: block;
    position: relative;
    content: '';
    z-index: -1;
    text-align: left;
    transition: 0.3s;
    transform: rotate(0deg) scale(1);
}
.servicios .single_box::before {
    text-align: left;
    transition: 0.3s;
    transform: rotate(0deg) scale(1);
}

.servicios .single_box .box__icon {
    position: absolute;
    font-size: 80px;
    font-weight: 800;
    right: 0;
    bottom: 0;
    line-height: 1;
    z-index: -1;
    left: 0;
    top: 0;
    text-align: left;
    opacity: 0.1;
    transition: 0.3s;
}

.servicios .single_box .box__title {
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin: 0px 0px -10px 0px;
    color: #ffffff;
}
.servicios .single_box .box__title::before {
    display: block;
    position: relative;
    content: '';
}
.servicios .single_box .tel {
    color: white;
}

.no-webp .servicios .bg-custom {
    background-image: url(../images/servicios/post.jpg);
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-attachment: scroll;
}
.webp .servicios .bg-custom {
    background-image: url(../images/servicios/post.webp);
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-attachment: scroll;
    position: relative;
}
.servicios .overlay-custom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        rgb(2, 32, 49),
        rgba(34, 4, 99, 0.8)
    );
}

/* ====================== 
* Servicios FIN
========================*/

/* ====================== 
* Sobre nosoros INCIO
========================*/
.sobre-nosotros .kl-iconbox__icon {
    width: 100px;
}
/* ====================== 
* Sobre nosoros FIN
========================*/

/* ==========================================================================
** Responsive styles custom rules
* These rules are ready to be used for either overrides
* or just your own custom rules.
========================================================================== */

/** Higher screens+ **/
@media only screen and (min-width: 1921px) {
}

/** Desktop+ **/
@media (min-width: 1200px) {
}

/** Laptop **/
@media (max-width: 1200px) {
}

/** Laptop+ **/
@media (min-width: 991px) {
}

/** Laptop **/
@media (min-width: 992px) and (max-width: 1199px) {
}

/** Large Tablets **/
@media (max-width: 992px) {
}

/** Tablets (landscape mode)  **/
@media (min-width: 768px) and (max-width: 991px) {
}

/** Tablets+  **/
@media (min-width: 768px) {
}

/** Tablets (portrait mode)  **/
@media (min-width: 481px) and (max-width: 768px) {
}

/** Smartphone landscape mode / Mini Tablet **/
@media (max-width: 767px) {
}

/** Smartphone landscape mode / Mini Tablet **/
@media (max-width: 600px) {
}

/** Smartphone portrait mode **/
@media (max-width: 480px) {
}

/** Normal phone portrait mode+ **/
@media (min-width: 320px) {
}
